export const Dart = [{
    name:'Electronic_Dart_Board',
    keyword:'darts,Electronic Dart Board,donkeez sports darts,cabinet with eletronic darts,donkeez sports darts,donkeez sports soccer table,soccer table',
    category:'dart',
    latest:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fdarts%2Fdarts_latest2.jpg?alt=media&token=b8ec1f49-3202-4053-a9cb-b55b79177824',
    discount:699.00,
    amount:899.00,
    stock:1,
    desc:'The total comfort Electronic Dart Board with playable for a whole family Power: By a adapter,Display: 4 LED Display',
    specification:[
        {name:'Game',desc:'18 Games and 159 Variation'},
        {name:'Guidance',desc:'Support English'},
        {name:'Score',desc:'1-8 players'},
        {name:'Power',desc:'By adapter Local plug'},
        {name:'Display',desc:'4 LED Display'}
    ],
    accessories:['1 * Adaptor','6 * soft-tipped dart','24 * extra soft tip','1 * Manual'],
      weight:{'nett':'8.5KG','gross':'10KG'},
      dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fdarts%2Fdimension%2Fdart_dimension_1.svg?alt=media&token=5aeca0d3-217a-4800-a58b-c92e5260a442'},
                  {name:'inside',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fdarts%2Fdimension%2Fdart_dimension_2.svg?alt=media&token=de8d3d36-75bf-4810-a975-2d53a2dad31a'},
    ],
    variant:[{
              name:'BLACK',
              color:'#363936',
              images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fdarts%2Fdarts_center.jpg?alt=media&token=66678490-1ed7-4b8f-88f5-ba4301d2568d',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fdarts%2Fdarts_2.jpg?alt=media&token=72cd9dc2-a72d-4387-84ab-7aca46ad1546',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fdarts%2Fdarts_3.jpg?alt=media&token=2b9845bc-676e-4860-90da-99c214c23e90',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fdarts%2Fdarts_4.jpg?alt=media&token=10654bc0-8384-4e66-a444-749f147d1222'
            ]
            }]
  }
]



export default Dart