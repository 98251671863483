import Pool from './pool';
import Foosball from './foosball';
import AirHockey from './airhockey';
import Darts from './dart';
import Revolver from './revolver';
import AccessoriesProducts from './accessories';

const Product =  [...Revolver,...Pool,...Foosball,...AirHockey,...Darts,...AccessoriesProducts]


export function Products(icategory,iname){
    const Selected = Product.find(({category,name})=> category.includes(icategory) && name == iname);
    return Selected?Selected:null 
  }



export function ProductList(name){
    const result = Product.filter(({ category  }) => category.includes(name));
    return result.length?result:null
}

export function NewProducts(){
  const result = Product.filter(({ latest  }) => latest);
  return result.length?result:null
}

export function Accessories(limit){
  if(limit){
    return AccessoriesProducts.slice(0,limit);
  }else{
    return AccessoriesProducts;
  }
}

  export function BannerList(props){
    return [{
        Primary:'#2b2b2a',     
        Secondary: '#eee',
        Background:'#eee',
        Link:'#004ed4',
        Tab:"#aaa",
        Text:'#444',
        Chat:'#e3e3e3',
        dark:'#666',
        Error:'#ff5162',
    }]
  }
  

 