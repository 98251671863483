import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Text from '../../assets/component/text';
import Footer from '../footer';
import {Helmet} from "react-helmet";
import Types from './types';
import Banner from './banner';
import NewArrival from './newarrival'
import Assecories from './accessory'
import Services from './service'


export default function HomeScreen({route,navigation}) {



  useEffect(() => {

    
  }, []);

  const Discount = () => {
    return (
      <View style={{flexDirection:'row',justifyContent:'center',marginBottom:10}}>
         <View style={styles.promo}><Text style={{color:'#fff',fontWeight:'200',fontSize:14}}>BUY ONLINE MORE % DISCOUNT</Text></View>
      </View>
    )
  }




  return (
    <ScrollView style={{flex:1,paddingHorizontal:'4%',paddingVertical:20}}>
       <Helmet>
                <title>Donkeez Sports (MY)</title>
                <meta name="title" content="Donkeez Sports (MY)"/>
                <meta name="description" content="Whole New Entertaiment For your Home , Office & Outdoor We Bring More Funs to You and Family range of POOL , Billiard , Snooker , Foosball , Hockey Table , Ping Pong Table , Darts and More"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://donkeez.com/"/>
                <meta property="og:title" content="Donkeez Sports (MY)"/>
                <meta property="og:description" content="Whole New Entertaiment For your Home , Office & Outdoor We Bring More Funs to You and Family range of POOL , Billiard , Snooker , Foosball , Hockey Table , Ping Pong Table , Darts and More"/>
                <meta property="og:image" content=""/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://donkeez.com/"/>
                <meta property="twitter:title" content="Donkeez Sports (MY)"/>
                <meta property="twitter:description" content="Whole New Entertaiment For your Home , Office & Outdoor We Bring More Funs to You and Family range of POOL , Billiard , Snooker , Foosball , Hockey Table , Ping Pong Table , Darts and More"/>
                <meta property="twitter:image" content=""/>
                <meta name="keywords" content="donkeez,dkz,donkezz,donkey sports,donkkee,donke sports,donkeez my,donkeez sports,donkeez malaysia,donkeez pool table, donkeez foosball,donkeez ping pong,donkeez darts,donkeez pinball" />
        </Helmet>
        <Discount/>
      <Types nav={navigation}/>
      <NewArrival nav={navigation}/>
      <Banner image={'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fbanner%2Fgreen_banner_40%25.jpeg?alt=media&token=86aa9ac1-f0f8-44eb-85ea-6ac2636aa4ba'}/>
      <Assecories nav={navigation}/>
      <Banner image={'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fmulti.png?alt=media&token=b49af1d1-7124-4eaa-b2dd-ad5e77a3f79f'}/>
      <Services/>

      <Footer nav={navigation}/>

    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  promo:{
    backgroundColor:'#ff5400',
    padding:7,
    borderRadius:5,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 10,
  }
});
