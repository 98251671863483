import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image,useWindowDimensions} from 'react-native';
import Text,{TextLight} from '../../../assets/component/text';


export default function MobileBanner(props) {

  const {img} = props



    return (

                <View style={[{flex:1}]}>
                  <Image style={styles.image} source={{uri: img}}/>
                </View>

    )


}

const styles = StyleSheet.create({
  image:{
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1.77 // Your aspect ratio
  }
});
