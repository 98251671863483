import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image,useWindowDimensions} from 'react-native';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import Text,{TextLight} from '../../../assets/component/text';


export default function Newarrivals(props) {


  useEffect(() => {

    
  }, []);

  const Service = [
    {name:'SERVICE',icon:'toolbox'},
    {name:'MOVING',icon:'truck-delivery'},
    {name:'INSTALLATION',icon:'tools'},
  ]


  const ListBx = (data) => {
    return (
        <View style={{flexDirection:'row',margin:5}}>
          {  
          data.map((val,i)=>{
                return(
                    <View style={styles.listbx} key={i}>
                        <MaterialCommunityIcons name={val.icon} size={50} color="#777"/>
                        <Text style={{fontSize:12,color:'#777'}}>{val.name}</Text>
                    </View>
                )
            })
            }
        </View>
    )
  }
  




  return (

    <View style={{alignItems:'center',marginVertical:10}}>
      <View style={{marginVertical:10,alignItems:'center'}}>
            <Text style={styles.head}>OUR SERVICES</Text>
            <TextLight>We provide all the services you needed</TextLight>
      </View>
            {ListBx(Service)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padd:10,
    borderWidth:1,
    flexDirection:'row',
    borderRadius:5,
    borderColor:'#ddd',
    alignContent:'center',
    marginBottom:20
  },
  head:{
    fontSize:16,
    fontWeight:'500'
  },
  listbx:{
    padding:8,
    marginBottom:10,
    marginRight:10,
    alignItems:'center',
    borderWidth:1,
    borderRadius:4,
    borderColor:'#eee'
  },
  image:{
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1.77 // Your aspect ratio
  }
});
