import DetailsTable from './Details';
import ListTable from './List';



export function List(props){
  return ListTable(props)
}


export function Details(props){
  return DetailsTable(props)
}
