import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Image,useWindowDimensions} from 'react-native';
import Text,{TextLight} from "../../assets/component/text";
import EvilIcon from '@expo/vector-icons/EvilIcons';



export default function Breadcrumbs(props) {

    const {category,name} = props.list



    const Maps = (data,chevron) => {
      if(!data) return null
      return (
        <View style={[styles.flexcenter]}>
          {chevron&&<EvilIcon name="chevron-right" size={20} color="#777" style={{marginTop:2}}/>}
          <View style={styles.breadBtn}>
            <TextLight style={styles.title}>{data.replaceAll('_', ' ')}</TextLight>
          </View>
        </View>

      )
    }

      return (
        <View style={[styles.flexcenter]}>
          <Image source={require('./breadcrumb.svg')} style={styles.icon}/>
          {Maps('Home')}
          {Maps(category,true)}
          {Maps(name,true)}
        </View>
      )
}

const styles = StyleSheet.create({
    flexcenter:{
      flexDirection:'row',
      alignItems:'center',
      alignContent:'center'
    },
    icon:{
      width:20,
      height:20
    },
    breadBtn:{
      paddingHorizontal:5
    },
    title:{
      textTransform:'capitalize'
    }
});
