import React, { useState,useEffect } from 'react';
import { StyleSheet,View,useWindowDimensions, TouchableOpacity,Image} from 'react-native';
import {Helmet} from "react-helmet";
import Footer from '../../footer';
import Text,{TextLight} from '../../../assets/component/text';
import Whatsapp from '../../../assets/component/whatsapp';
import { ScrollView } from 'react-native-gesture-handler';
import NotFound from '../../notfound';
import {ProductList} from '../../data';
import {HeaderSitemap} from '../../../navigation/data';


export default function ProductListScreen({route,navigation}) {

  const {name} = route.params;


  const [data, setData] = useState([]);
  
  useEffect(() => {
    GetProduct()
    window.scrollTo(0, 0);
  }, [name]);

  async function GetProduct(){

    const Result = await ProductList(name)
    if(Result){
      setData(Result)
    }else{
      setData(null)
    }
  }



  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }



  if(!data){
    return <NotFound/>
  }

  const OutStock = (stock) => {
    if(stock) return null
    return (
      <View style={styles.outbx}>
        <View style={styles.outbx1}>
          <Text style={{color:'red',fontWeight:'500'}}>OUT OF STOCK</Text>
        </View>
      </View>
    )
  }

  const ProductBox = (val) => {
    const Discount = numberWithCommas(parseFloat(val.discount).toFixed(2))
    const Amount = numberWithCommas(parseFloat(val.amount).toFixed(2))
    const pImage = val.variant && val.variant[0].images[0]

    return (
      <TouchableOpacity style={{flex:1}} onPress={()=>navigation.navigate('details', {category:name,name: val.name})}>

                <Image source={{uri: pImage,cache:'force-cache',}} style={styles.image}/>
                {OutStock(val.stock)}
                <View style={styles.titlebx}>
                  <Text style={{fontSize:18,fontWeight:'200',fontFamily :'serif',color:'#777'}}>{val.name.replaceAll('_', ' ')}</Text>

                  <Text style={{fontSize:18,fontWeight:'800',fontFamily :'serif',color:'#ff5757'}}>RM {Discount}</Text>
                <TextLight style={{fontSize:10,color:'#999'}}>USUAL PRICE</TextLight>
                <TextLight style={{fontSize:15,color:'#999',fontFamily :'serif',textDecorationLine: 'line-through'}}>RM {Amount}</TextLight>
                <View style={{marginVertical:5}}>
                  <View style={styles.more}>
                      <Text style={{color:'#fff'}}>MORE</Text>
                  </View>
                </View>
                </View>
      </TouchableOpacity>
    )
  }
  

  const ProductBx  = () => {

    return (
      <View style={{flexDirection:'row',flexWrap:'wrap',flex:1}}>
        {data.map((val,i) => {
          return (
              <View style={styles.dimensionimg} key={i}>
                {ProductBox(val)}
              </View>
          )
        })}
      </View>
    )
  }

  const LargeScreen = () => {
    const dimensions = useWindowDimensions();
  
    const isLargeScreen = dimensions.width >= 768;
    return isLargeScreen
  }

  const Banner = () => {
    const IMG = LargeScreen()?'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fbanner_vertical.jpeg?alt=media&token=378f699d-8f89-444a-98f5-e12992729e9a': 'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fpower.png?alt=media&token=bed4769c-4049-4f84-8854-51e797316af7'
    return(
    <View style={{height:LargeScreen()?250:180}}>
        <Image style={styles.banner} source={{uri: IMG}}/>
    </View>
    )
  };

  const Iconbx = () => {
    const List = HeaderSitemap()[0].list
    let Product = List.find(o => o.name.toLowerCase() === name);
    if(!Product) return  null
    return (
      <View style={{padding:5,height:50,width:50,borderRadius:50,backgroundColor:'#eee',marginRight:10}}>
        <Image source={Product.icon} style={styles.icon}/>
      </View>
    )
  }

  const Head = (props) => {
    const {title} = props
    return (
      <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="site_name" content={title} />
                <meta name="title" content={title} data-react-helmet="true"/>
                <meta name="description" content={"Whole New Donkeez Sports range of products "+title} />
                <meta name="keywords" content={"Donkeez Sports "+title} />
          </Helmet>
    )
  }



  return (
    <View style={{flex:1}}>
      <Head title={name.replaceAll('_', ' ')}/>
      <Whatsapp/>
      <ScrollView style={{flex:1}}>
          <Banner/>
          <View style={{flex:1,paddingHorizontal:'4%',paddingVertical:20}}>
          <View style={{flex:1}}>
            <View style={{flexDirection:'row',alignItems:'center',paddingVertical:10,borderBottomWidth:1,borderColor:'#eee'}}>
              <Iconbx/>
              <View style={{flexShrink:1}}>
                  <TextLight style={{fontSize:24,textTransform: 'capitalize'}}>{name.replaceAll('_', ' ')} Series</TextLight>
                  <TextLight style={{textTransform: 'capitalize',fontSize:12,color:'#aaa',}}>With all of games in 1 rooms with all in series</TextLight>
              </View>
            </View>
            <ProductBx/>
          </View>
          <Footer nav={navigation} category={name}/>
        </View>

    </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  head:{
    fontSize:26,
    fontWeight:'400'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  banner: {
    resizeMode: 'cover',
    flex: 1,
  },
  titlebx:{
    padding:5,
    alignContent:'flex-end',
    alignItems:'flex-end'
  },
  more:{
    flexDirection:'row',
    backgroundColor:'#ff5757',
    padding:5,
    borderRadius:5
  },
  dimensionimg:{
    flex: 1,
    height:360,
    minWidth:200,
    maxWidth:400,
    padding:20
  },
  icon:{
    flex: 1,
    resizeMode: 'contain',
  },
  outbx:{
    position:'absolute',
    width:'100%',
    height:'100%',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  },
  outbx1:{
    padding:5,
    borderRadius:4,
    backgroundColor:'#FFFFFFC9'
  }
});
