
export function HeaderSitemap(){

  const Contents = [
    {'name':'Products',
      'list':[
      {name:'Pool',icon:require('./icon/pool_icon.svg')},
      {name:'Foosball',icon:require('./icon/foosball_icon.svg')},
      {name:'Air_Hockey',icon:require('./icon/air_hockey.svg')},
      {name:'Table_Tennis',icon:require('./icon/ping_icon.svg')},
      {name:'Dart',icon:require('./icon/dart_icon.svg')},
      ]},
    {'name':'About_Us',
      'list':[{name:'Company'},{name:'Clients'}]},
    {'name':'Contact_Us',
      'list':[{name:'Contact'}]},
    {'name':'How to',
      'list':[{name:'Contact'},{name:'Feedback'},{name:'Directory'}]}
  ]
    return Contents

}


  

 