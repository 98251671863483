import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Image,useWindowDimensions} from 'react-native';
import Text,{TextLight} from "../../assets/component/text";
import TextInput from "../../assets/component/textinput";
import Button from "../../assets/component/button";
import Ionicons from '@expo/vector-icons/Ionicons';




export default function Newsletter(props) {

    const {nav,screen} = props

    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);




  function validateDomain(email, companyNameArr){ 
    let match = email.match(/^\w+@(\w+).\w+$/);  
    return match!==null &&  companyNameArr.includes(match[1]);
  }

    const RowStyles  = screen ? {alignItems:'center',justifyContent:'center',marginVertical:20} : {alignContent:'flex-end',justifyContent:'flex-end',marginVertical:20}

    function Validate(){
      if(email.error) return null
        if(validateDomain(email,['gmail','yahoo'])){
          setSuccess(true)
        }else{
          setEmail({error:'Invalid Email',val:email})
        }
    }

    const SuccessBx = () => {
      return (
        <View style={[styles.flexcenter,{padding:5}]}>
          <Ionicons name={'checkmark-circle'} size={22} color={'#9ec76f'}/>
          <Text style={{color:'#9ec76f',marginLeft:5}}>Successfully subscribe to our newsletter</Text>
        </View>
      )
    }

    if(success) return <SuccessBx/>

    return (
      <View style={RowStyles}>
        <View style={{margin:5}}><Text style={styles.bold}>SUBSCRIBE TO OUR NEWSLETTER</Text></View>
        <View style={{flexDirection:'row'}}>
          <View style={{flex:1}}><TextInput value={email} onChangeText={setEmail} placeholder={'subscribe@donkeez.com'}/></View>
          <View style={{marginLeft:5}}><Button title={"SUBSCRIBE"} onPress={Validate} icon={screen && 'newspaper'} color={'#5a9e55'} style={{padding:12}}/></View>
        </View>
        <View style={{marginVertical:2,marginHorizontal:5}}>
          <TextLight style={{fontSize:12,color:'#aaa'}}>we share email of our latest promotion and updates</TextLight>
        </View>
      </View>
    )
 
}

    

const styles = StyleSheet.create({
    container:{
      padding:10,
      marginTop:50
    },
    flexcenter:{
      flexDirection:'row',
      alignItems:'center',
      alignContent:'center',
      justifyContent:'center'
    },
    icon:{
      width:20,
      height:20
    },
    breadBtn:{
      paddingHorizontal:5
    },
    copyright:{
      padding:10,
      justifyContent:'center'
    },
    SiteMap:{
      flexDirection:'row',
      marginVertical:20
    },
    mSiteMap:{
      paddingVertical:10,
      borderBottomWidth:1,
      borderBottomColor:'#ccc'
    },
    mSiteMapLast:{
      paddingVertical:10,
    },
    bold:{
      fontWeight:'500',
      fontSize:12,
      color:'#777'
    }
});
