export const Revolver = [ { 
name:'4_In_1_Multi_Games_Table', 
latest:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_pool-fotor-2023090917472.jpg?alt=media&token=a3617bce-02e2-4db5-97b7-f0f1f2cde9a9',
keyword:'mdf table,donkeez sports table,Multi Games table,Air hockey,Ping Pong,Table Tennis,Billiard,donkeez sports billiards',
category:['pool','air_hockey','table_tennis'],
discount:2999.00,
amount:3299.00,
stock:1,
desc:'The total comfort 4 in 1 Multi games table with playable for a whole family',
accessories:['1 set 2 1/4" balls','2 * pcs 57" cues','1 * Brush','1 * Triangle','2 * Chalks','2 * Pucks','2 * Pusher','3 * Ping Pong Balls','2 * Racket','1 * Net'],
weight:{'nett':'120KG','gross':'135KG'},
dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fmultirevolver%2Fdimension%2FPage%201%20(2).svg?alt=media&token=f16ab4d3-5304-4801-9f1a-234f41a702d2'},
{name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_2.svg?alt=media&token=3bcee180-c156-4262-8dfd-75677e0f5752'},
{name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_3.svg?alt=media&token=63664b3b-32b6-49f5-b8b5-b37c2726334d'}
],
specification:[
{name:'Material',desc:'Mdf Black'},
{name:'Ping Pong',desc:'12mm Blue'},
{name:'Dinning',desc:'12mm Black'},
],
variant:[{
          name:'BLUE',
          color:'#3886c8',
          images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_pool-fotor-2023090917472.jpg?alt=media&token=a3617bce-02e2-4db5-97b7-f0f1f2cde9a9',
          'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_air-fotor-20230909174542.jpg?alt=media&token=de348b6d-77a3-4b39-b092-b4b3c66903c5',
          'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_ping-fotor-20230909174616.jpg?alt=media&token=a9973d07-00b9-41b3-8052-7de1618b91ed',
          'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_dining.jpg?alt=media&token=a27e1eab-6c76-4e8c-830d-5d94091633ee'                ]
          },{
          name:'BLACK',
          color:'#444',
          images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_pool_dark-fotor-20230909174751.jpg?alt=media&token=130f9de8-b146-4425-8ba1-b82c276cbeef',
                  'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_air-fotor-20230909174542.jpg?alt=media&token=de348b6d-77a3-4b39-b092-b4b3c66903c5',
                  'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_ping-fotor-20230909174616.jpg?alt=media&token=a9973d07-00b9-41b3-8052-7de1618b91ed',
                  'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2F4_in_1_7ft%2Fmulti_dining.jpg?alt=media&token=a27e1eab-6c76-4e8c-830d-5d94091633ee'
                ]
        }]
},
]



export default Revolver