import React, { useState,useEffect } from 'react';
import { StyleSheet,View,useWindowDimensions, TouchableOpacity,Image } from 'react-native';
import Footer from '../../footer';
import Text,{TextLight} from '../../../assets/component/text';
import Button from '../../../assets/component/button';
import { ScrollView } from 'react-native-gesture-handler';
import {Helmet} from "react-helmet";




export default function AboutUs(props) {

  
  const {nav} = props

  const Description  = () => {
    return  (
      <View style={{flex:4}}>
          <Text style={styles.desc}>"We appreciate all our clients" is a statement that expresses gratitude and acknowledgement towards the people or organizations who have engaged the services or purchased the products of a company.</Text>
          <Text style={styles.desc}>Overall, showing appreciation towards clients is a simple but effective way to build strong and lasting relationships, and it is an important aspect of successful business operations.</Text>
      </View>
    )
  }

  const Clients  = () => {
    return  (
      <View style={{padding:10,borderRightWidth:1,borderColor:'#ccc',marginRight:10,flex:1}}>
          <View style={styles.box}/>
          <View style={styles.box}/>
          <View style={styles.box}/>
      </View>
    )
  }





  return (
    <ScrollView style={{flex:1,paddingHorizontal:'4%',paddingVertical:20}}>
        <View style={{flex:1}}>
          <Text style={styles.titlebx}>Our Clients</Text>
          <View style={{flexDirection:'row',marginVertical:20}}>
              <Clients/>
              <Description/>
          </View>
        </View>
        <Footer nav={nav} category={'Warranty'} name={'About Us'}/>

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  desc:{
    marginVertical:10,
    color:'#666'
  },
  titlebx:{
    fontSize:22,
  },
  box:{
    height:100,
    borderWidth:1,
    borderColor:'#ccc',
    marginBottom:10
  }

});
