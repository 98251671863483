import { StyleSheet,View,useWindowDimensions, TouchableOpacity,Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Footer from './footer';
import {Helmet} from "react-helmet";

export default function NotFoundScreen(props) {

  const navigation = useNavigation();

  return (
    <View style={{flex:1}}>
    <Helmet>
        <meta charSet="utf-8" />
        <meta name="site_name" content="Page Not Found" />
        <title>Page Not Found</title>
    </Helmet>
    <View style={{padding:5,justifyContent:'center',alignItems:'center',flex:1,minHeight:300}}>
      <h1>Error 404</h1>
      <h4>Page not found</h4>
    </View>
    <Footer nav={navigation} category={'Not Found'} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
