import React, { useState,useEffect } from 'react';
import { View,StyleSheet,Text,TouchableOpacity,Image,useWindowDimensions, Pressable} from 'react-native';
import { useDrawerStatus } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import {Ionicons,EvilIcons} from '@expo/vector-icons';
import { BlurView } from 'expo-blur';
import {HeaderSitemap} from './data';
import COLORS from '../assets/component/colors';




export default function Header(props) {

  const {data} = props
  const isDrawerOpen = useDrawerStatus() === 'open';

    const navigation = useNavigation();

    const [selected, setSelected] = useState(null);

    useEffect(() => {
      window.scrollTo(0, 0);

    }, [props]);
    

    const LargeScreen = () => {
      const dimensions = useWindowDimensions();
    
      const isLargeScreen = dimensions.width >= 768;
      return isLargeScreen
    }

    function Hover(Title){
      if(selected && selected.name !== Title.name){
        setSelected(selected && selected.name == Title.name?null:Title)
      }else{
        setSelected(Title)
      }
      //navigation.navigate(NavName, {name: NavName})

    }

    async function Navi(Title,name){
      setSelected(null)
      navigation.navigate(Title.toLowerCase(), {name: name.toLowerCase()})

    }
    

    const MenuBtn = () => {
      if(LargeScreen()) return null
      return (
        <View style={{width:30,height:50,alignContent:'center',alignItems:'center',justifyContent:'center'}}>
            <TouchableOpacity style={styles.icon} onPress={()=>navigation.toggleDrawer()}>
              <EvilIcons name={isDrawerOpen?'close':'chevron-left'} size={isDrawerOpen?20:32} color="#777" />
            </TouchableOpacity>
        </View>
      )
    }

    const CartBtn = () => {
      if(isDrawerOpen) return null
      return (
        <TouchableOpacity style={styles.icon} onPress={() => console.log("Cart")}>
          <Ionicons name="ios-basket" size={24} color="#777" />
        </TouchableOpacity>
      )
    }

    const Logo = () => {

      return (
        <TouchableOpacity onPress={() => navigation.navigate('Home')}>
          <Image source={require('../assets/images/logo_vertical_invert.svg')} style={styles.Logo}  resizeMode="contain"/>
        </TouchableOpacity>
      )
    }

    const HorizontalMenu = () => {
      return (
       <View style={styles.menu}>
         {HeaderSitemap().map((item,i)=> {
            return (
              <Pressable style={styles.menubx} key={i} onHoverIn={()=>Hover(item,i)} onPress={()=>Hover(item,i)}>
                  <Text style={styles.txt}>{item.name.replaceAll('_', ' ')}</Text>
              </Pressable>
            )
         })}
       </View>
      )
    }


    

    const TitleHeader = () => {
      if(!LargeScreen()) return <View style={{flex:1}}/>
      return (
        <View style={{flex:1,marginLeft:10,flexDirection:'row',alignContent:'center',flex:1,alignItems:'center',borderLeftWidth:1,borderRightWidth:1,borderColor:'#444'}}>
            <HorizontalMenu/>
        </View>
      )
    }

    const Expand = () => {
      const dimensions = useWindowDimensions();
      if(!selected || !LargeScreen()) return null

      return (
        <View style={{position:'absolute',top:50,width:'100%',height:dimensions.height}}>

        <Pressable style={{paddingBottom:30,backgroundColor:'#2b2b2a',flexDirection:'row'}} onHoverOut={()=>setSelected(null)}>
              <View style={{width:170}}/>
              <View>
                <Text style={{fontSize:24,fontWeight:'300',color:'#aaa'}}>{selected.name.replaceAll('_', ' ')}</Text>
                {selected.list.map((item,i)=> 
                      <TouchableOpacity key={i} style={{paddingVertical:2}} onPress={()=>Navi(selected.name,item.name)}>
                          <Text style={{color:'#999',fontSize:15,fontWeight:'200'}}>{item.name.replaceAll('_', ' ')}</Text>
                      </TouchableOpacity>
                )}
              </View>

        </Pressable>
        <TouchableOpacity style={{flex:1}} onPress={()=>setSelected(null)}>
            <BlurView intensity={20} tint="light" style={{flex:1}}/>
        </TouchableOpacity>

        </View>
      )
    }
    

    return (
      <View>
            <View style={[styles.container,styles.flexcenter]}>
              {Logo()}
              <TitleHeader/>
              <CartBtn/>
              <MenuBtn/>
            </View>
          <Expand/>
      </View>

    );
}

const styles = StyleSheet.create({
 
  container: {
    flexDirection:'row',
    padding:10,
    alignContent:'center',
    alignItems:'center',
    shadowColor: "#777",
    backgroundColor:COLORS.Primary,
    shadowOffset: {
      width: 0,
      height: 17,
    },
    shadowOpacity: 0.2,
    shadowRadius: 18,
    elevation: 22,
    height:50
  },
  flexcenter:{
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
  },
  menu:{
    flexDirection:'row'
  },
  Logo:{
    width:140,
    height:40,
  },
  menubx:{
    paddingHorizontal:10,
  },
  txt:{
    color:'#aaa'
  },
  icon:{
    padding:5,
  }



  });