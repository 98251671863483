import React, { Component } from "react";
import {View,TextInput,StyleSheet,TouchableOpacity, ActivityIndicator} from "react-native";
import COLORS from "./colors";
import Text from "./text";
import Ionicons from '@expo/vector-icons/Ionicons';


export default function ButtonComponent(props) {

    const {title,icon,color,isloading,style} = props;


    if(isloading){
      return(
        <View style={styles.container} {...props}>
          <View style={[styles.BtnLoading]}>
            <View><ActivityIndicator size={'small'}/></View>
          </View>
        </View>
      )
    }


    return (
        <View style={[styles.container]} >
            <TouchableOpacity {...props} style={[styles.btnAdd,style,{backgroundColor:color?color:COLORS.Primary}]}>
              {icon?<Ionicons name={icon} size={20} color='#fff' style={{marginRight:title?5:0}}/>:null}
                  <Text style={{color:'#fff'}}>{title}</Text>
            </TouchableOpacity>
        </View>
    );

  }


    const styles = StyleSheet.create({
      container:{
        flexDirection:'row',
        justifyContent:'flex-end',
      },
      btnAdd:{
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
      },
      BtnLoading:{
        width:100,
        padding:10,
        backgroundColor:'#eee',
        borderRadius:4,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center'
      }
    
});

  