import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image,useWindowDimensions} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Text,{TextLight} from '../../../assets/component/text';
import {NewProducts} from '../../data'


export default function Newarrivals(props) {

  const {nav} = props

  const [data, setData] = useState([]);

  useEffect(() => {
    GetProduct()
  }, []);

  async function GetProduct(){
    const Result = await NewProducts(name)
    if(Result){
      setData(Result)
    }else{
      setData(null)
    }
  }

  const ListBx = (data) => {
    return (
        <View style={{flexDirection:'row',marginVertical:5,flexWrap:'wrap'}}>
          {  
          data.map((val,i)=>{
                const NavName = val.name
                const Category = typeof val.category === 'object' && val.category !== null?val.category[0]:val.category
                const pImage = val.latest
                return(
                    <TouchableOpacity style={styles.listbx} key={i} onPress={()=>nav.navigate('details', {category:Category,name: NavName})}>
                        <Image source={{uri:pImage}} style={styles.image}/>
                        <Text style={{fontSize:16,fontWeight:'500'}}>{val.name.replaceAll('_', ' ')}</Text>
                        <TextLight numberOfLines ={2}>{val.desc}</TextLight>
                    </TouchableOpacity>
                )
            })
            }
        </View>
    )
  }
  


  if(!data.length) return null

  return (

    <View>
            <Text style={styles.head}>New Arrival !!</Text>
            <TextLight>Discover our new range products</TextLight>
            {ListBx(data)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padd:10,
    borderWidth:1,
    flexDirection:'row',
    borderRadius:5,
    borderColor:'#ddd',
    alignContent:'center',
    marginBottom:20
  },
  head:{
    fontSize:18,
    fontWeight:'500'
  },
  listbx:{
    width:280,
    height:320,
    padding:4,
    marginVertical:20,
    marginHorizontal:10,
  },
  image:{
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1.77 // Your aspect ratio
  }
});
