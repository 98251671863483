import React, { useState,useEffect } from 'react';
import { StyleSheet,View,useWindowDimensions, TouchableOpacity,Linking } from 'react-native';
import Footer from '../../footer';
import Text,{TextLight} from '../../../assets/component/text';
import Button from '../../../assets/component/button';
import {FontAwesome} from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';
import {Helmet} from "react-helmet";




export default function AboutUs(props) {

  const {nav} = props
  
  useEffect(() => {

  }, []);

  const BoxList = (props) => {
    const {icon,title,desc,cust,btn}  = props
    return (
      <View style={[styles.box,styles.flexcenter]}>
          <FontAwesome name={icon} size={40} color="#444"/>
          <TextLight style={{fontSize:20,color:'#444',marginTop:10}}>{title}</TextLight>
          <TextLight style={{color:'#444',textAlign:'center'}}>{desc}</TextLight>
          {cust}
          {btn}
      </View>

    )
  }



  const ChatHour = () => {
    return (
      <View style={[styles.flexcenter,{marginVertical:5}]}>
        <Text>Monday - Friday</Text>
        <TextLight>9:00 AM - 5.30PM</TextLight>
      </View>
    )
  }

  const Call = () => {
    return (
      <View style={[styles.flexcenter,{marginVertical:5}]}>
        <Text style={{fontSize:20}}>03-5021 9189</Text>
        <Text>Monday - Friday</Text>
        <TextLight>9:00 AM - 5.30PM</TextLight>
      </View>
    )
  }




  return (
    <ScrollView style={{flex:1,paddingHorizontal:'4%',paddingVertical:20}}>
        <View style={{flex:1,minHeight:300}}>

          <View style={{flexWrap:'wrap',flexDirection:'row'}}>
              <BoxList icon={'gears'} title={'Feedback'} desc={'Drop us a feedback for us to improve more'}/>
              <BoxList 
                icon={'comments-o'} 
                title={'Live Chat'} 
                desc={'Need Help? Chat with our live agent via Whatsapp Chat'} 
                cust={<ChatHour/>}
                btn={<Button title={'CHAT WITH US'} onPress={()=>Linking.openURL('whatsapp://send?text=DonkeeSports&phone=+601156213067')}/>}
                />
              <BoxList icon={'phone'} title={'Call Us'} desc={'connect with our hotline call center'} cust={<Call/>}/>
          </View>
        </View>
        <Footer nav={nav} category={'About Us'} name={'Contact Us'}/>

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  flexcenter:{
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center'
  },
  box:{
    borderWidth:1,
    borderColor:'#eee',
    borderRadius:5,
    flex:1,
    minWidth:239,
    minHeight:200,
    margin:5,
    padding:10,
    shadowColor: "#aaa",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11,
    elevation: 12,
  }
});
