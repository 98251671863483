import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image,useWindowDimensions} from 'react-native';
import Text,{TextLight} from '../../../assets/component/text';


export default function DesktopBanner(props) {

  const {img} = props



    return (
      <View style={styles.largebx}>
          <View style={[{flex:1,paddingHorizontal:20,maxWidth:500}]} >
            <Image style={styles.image} source={{uri: img}}/>
          </View>
      </View>
    )


}

const styles = StyleSheet.create({
  largebx:{
    flexDirection:'row',
    flexWrap:'wrap',
    flex:1,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center'
  },
  image:{
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1.77 // Your aspect ratio
  }
});
