import React, { useState,useEffect } from 'react';
import { StyleSheet,View,useWindowDimensions, TouchableOpacity,Image } from 'react-native';
import Footer from '../../footer';
import Text,{TextLight} from '../../../assets/component/text';
import Button from '../../../assets/component/button';
import { ScrollView } from 'react-native-gesture-handler';
import {Helmet} from "react-helmet";




export default function AboutUs(props) {

  
  const {nav} = props





  return (
    <ScrollView style={{flex:1,paddingHorizontal:'4%',paddingVertical:20}}>
        <View style={{flex:1}}>

          <Text>History</Text>
          <Text>ALL PAGES</Text>
        </View>
        <Footer nav={nav} category={'Warranty'} name={'About Us'}/>

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  titlebx:{
    padding:5,
    alignContent:'flex-end',
    alignItems:'flex-end'
  },

  dimensionimg:{
    flex: 1,
    height:360,
    minWidth:200,
    maxWidth:400,
    padding:20
  },
});
