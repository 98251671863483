import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity} from 'react-native';
import Text,{TextLight} from "../../assets/component/text";
import Ionicons from '@expo/vector-icons/Ionicons';
import {HeaderSitemap} from '../../navigation/data';




export default function Sitemap(props) {

    const {nav,screen} = props


    const [selected, setSelected] = useState(null);

    const SiteMapMobile = (data) => {


      function Navi(Title,NavName){
        setSelected(null)

          nav.navigate(Title, {name: NavName})

      }

      const Expanded = (item) => {
        const Title = item.name.toLowerCase()
        if(selected !== item.name) return null
        return (
          <View style={{padding:5}}>
          {item.list.map((item,i)=>{
            const NavName = item.name.toLowerCase()
            return (
                <TouchableOpacity key={i} style={{marginBottom:4}} onPress={()=>Navi(Title,NavName)}>
                    <TextLight style={{color:'#999'}}>{item.name.replaceAll('_', ' ')}</TextLight>
                </TouchableOpacity>
            )
          })}
          </View>
        )
      }

      return (
      <View style={{marginVertical:10}}>
          {data.map((item,i) => {
            const bxstyle = data.length == i+1 ?styles.mSiteMapLast:styles.mSiteMap

            const Select = item.name == selected

            return (
              <View style={bxstyle} key={i}>
                  <TouchableOpacity style={{flex:1}}  onPress={()=>setSelected(!Select?item.name:null)}>
                      <View style={styles.flexcenter}>
                        <View style={{flex:1}}><Text style={{marginBottom:4,color:'#555',fontWeight:'400'}}>{item.name.replaceAll('_', ' ')}</Text></View>
                        <Ionicons name={Select?"remove-outline":"add-outline"} size={14} color="#777"/>
                      </View>
                  </TouchableOpacity>
                  {Expanded(item)}
              </View>
            )
          })}
      </View>
      )

  }



  if(!screen) return SiteMapMobile(HeaderSitemap())

  return (
    <View style={[styles.SiteMap]}>
      {
        HeaderSitemap().map((item,i) => {
          const Link = item.name.toLowerCase()
          return (
            <View style={{flex:1}} key={i}>
              <Text  style={{marginBottom:4}}>{item.name.replaceAll('_', ' ')}</Text>
              <View style={{marginRight:5}}>
                  {item.list.map((item,i)=>{
                    const NavName = item.name.toLowerCase()
                    return (
                    <TouchableOpacity key={i} style={{marginBottom:4}} onPress={()=>nav.navigate(Link,{name:NavName})}>
                      <TextLight>{item.name.replaceAll('_', ' ')}</TextLight>
                    </TouchableOpacity>
                    )
                  })}
              </View>
            </View>
          )
        })
      }
  </View>
  )



}

const styles = StyleSheet.create({
    flexcenter:{
      flexDirection:'row',
      alignItems:'center',
      alignContent:'center'
    },
    SiteMap:{
      flexDirection:'row',
      marginVertical:20
    },
    mSiteMap:{
      paddingVertical:10,
      borderBottomWidth:1,
      borderBottomColor:'#eee'
    },
    mSiteMapLast:{
      paddingVertical:10,
    },
});
