
const COLORS = {
    Primary:'#2b2b2a',     
    Secondary: '#eee',
    Background:'#eee',
    Link:'#004ed4',
    Tab:"#aaa",
    Text:'#444',
    Chat:'#e3e3e3',
    dark:'#666',
    Error:'#ff5c6d',
}
  
export default COLORS