import React, { useState,useEffect } from 'react';
import { StyleSheet,View} from 'react-native';
import Footer from '../../footer';
import Text,{TextLight} from '../../../assets/component/text';
import Button from '../../../assets/component/button';
import { ScrollView } from 'react-native-gesture-handler';
import {Helmet} from "react-helmet";




export default function AboutUs(props) {

  
  const {nav} = props



  return (
    <ScrollView style={{flex:1,paddingHorizontal:'4%',paddingVertical:20}}>
        <View style={{flex:1}}>

          <Text style={styles.titlebx}>About Us</Text>
          <Text style={styles.desc}>DONKEEZ SPORTS is a leading supplier of games rooms, providing high-quality equipment and accessories to homes, businesses, and institutions. The company is known for its extensive range of games and sports equipment, including pool tables, foosball tables, air hockey tables, arcade games, and more.</Text>
          <Text style={styles.desc}>Donkeez Sports has a reputation for delivering top-quality products that are built to last, with an emphasis on design, functionality, and affordability. The company's expert team of designers and engineers work tirelessly to ensure that every product meets the highest standards of quality and performance.</Text>
          <Text style={styles.desc}>One of the key advantages of Donkeez Sports is its ability to provide a complete range of games room equipment, from individual games to complete setups. This means that customers can create a fully customized games room that meets their specific needs and preferences, whether they are looking for a traditional billiards room or a high-tech arcade experience.</Text>
          <Text style={styles.desc}>In addition to its extensive product range, Donkeez Sports also offers a wide range of services to support its customers. This includes expert installation and maintenance services, as well as ongoing customer support and advice.</Text>
          <Text style={styles.desc}>Overall, Donkeez Sports is a leading player in the games room industry, providing high-quality equipment and services to customers around the world. Whether you are a home owner looking to create the ultimate games room, or a business owner looking to add some entertainment options to your venue, Donkeez Sports is the go-to supplier for all your games room needs.</Text>
        </View>
        <Footer nav={nav} category={'Warranty'} name={'About Us'}/>

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  desc:{
    marginVertical:10,
    color:'#666'
  },
  titlebx:{
    fontSize:22,
  },

});
