import React, { useState,useEffect } from 'react';
import { StyleSheet,View,useWindowDimensions} from 'react-native';
import FontAwesome from '@expo/vector-icons/FontAwesome';




export default function Social(props) {

    const {nav,screen} = props



    const SizeIcon  = !screen ? 20:30
    const RowStyles  = screen ? {flexDirection:'row',justifyContent:'center',marginVertical:15} : {flexDirection:'row',alignContent:'flex-end',justifyContent:'flex-end',marginVertical:10}

    return (
      <View style={RowStyles}>
        <FontAwesome name="facebook" size={SizeIcon} color="#ccc" style={{marginRight:15}}/>
        <FontAwesome name="instagram" size={SizeIcon} color="#ccc" style={{marginRight:15}}/>
        <FontAwesome name="twitter" size={SizeIcon} color="#ccc" style={{marginRight:15}}/>
      </View>
    )

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      marginTop:50
    },
    flexcenter:{
      flexDirection:'row',
      alignItems:'center',
      alignContent:'center'
    },
    icon:{
      width:20,
      height:20
    },
    breadBtn:{
      paddingHorizontal:5
    },
    copyright:{
      padding:10,
      justifyContent:'center'
    },
    SiteMap:{
      flexDirection:'row',
      marginVertical:20
    },
    mSiteMap:{
      paddingVertical:10,
      borderBottomWidth:1,
      borderBottomColor:'#ccc'
    },
    mSiteMapLast:{
      paddingVertical:10,
    },
    bold:{
      fontWeight:'600',
      fontSize:12,
      color:'#999'
    }
});
