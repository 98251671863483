import React, { useState,useEffect } from 'react';
import { StyleSheet,View,useWindowDimensions, TouchableOpacity } from 'react-native';
import {useScrollToTop} from '@react-navigation/native';
import Footer from '../../footer'
import Text,{TextLight} from '../../../assets/component/text';
import Whatsapp from '../../../assets/component/whatsapp';
import Button from '../../../assets/component/button';
import { ScrollView } from 'react-native-gesture-handler';
import {Helmet} from "react-helmet";
import NotFound from '../../notfound';
import {FreeDelivery,Accessories,Specification,ProductImage,Dimension, Weight,ShareLink,ColorVariant} from '../component';
import {Products} from '../../data';


export default function ProductDetailsScreen({route,navigation}) {

  const {category,name} = route.params;

  const [data, setData] = useState([]);
  const [variant, setVariant] = useState(null);
  
  const ref = React.useRef(null);

  useScrollToTop(ref);

  useEffect(() => {
    ref.current?.scrollTo({
      y : 0,
      animated : true
  });
    GetProduct()
  }, [route]);

  useEffect(() => {

  }, [name]);



  async function GetProduct(){

    const Result = await Products(category,name)
    if(Result){
      setVariant(Result.variant && Result.variant[0])
      console.log(Result)
      setData(Result)
    }else{
      setData(null)
    }
  }

  const LargeScreen = () => {
    const dimensions = useWindowDimensions();
  
    const isLargeScreen = dimensions.width >= 768;
    return isLargeScreen
  }




  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  const ButtonBuy = () => {
    

    const Discount = numberWithCommas(parseFloat(data.discount).toFixed(2))
    const Amount = numberWithCommas(parseFloat(data.amount).toFixed(2))

    return (

        <View style={{flexDirection:'row',paddingHorizontal:5}}>
          <View style={{flex:1}}>
            <ColorVariant data={data.variant} value={variant} set={setVariant}/>
            <FreeDelivery/>
          </View>
          <View>
            <View style={{paddingVertical:10}}>
                <Text style={{fontSize:20,fontWeight:'500',fontFamily :'serif'}}>RM{Discount}</Text>
                <TextLight style={{fontSize:12,color:'#999'}}>USUAL PRICE</TextLight>
                <TextLight style={{fontSize:16,color:'#999',fontFamily :'serif',textDecorationLine: 'line-through'}}>RM{Amount}</TextLight>
            </View>
            <BuyNowBtn/>
          </View>
        </View>

    )
  }

  const BuyNowBtn = () => {
    if(!data.stock) return <NoStock/>
    return (
      <View><Button title={'Buy Now'} icon={'ios-cart'}/></View>
    )
  }

  const NoStock = () => {
    return (
      <View style={styles.nostockv}>
        <Text style={{color:'#fff'}}>Out Stock</Text>
      </View>
    )
  }


  const ProductDetails = () => {

    const Borderbx = LargeScreen()?{borderRightWidth:1,paddingRight:20,marginRight:15,borderColor:'#e4e4e4'}:null
    return (
      <View style={[Borderbx,{minWidth:300,maxWidth:600,flex:1,marginBottom:20}]} >
      <View style={{marginTop:20,marginRight:10}}><TextLight style={{fontSize:26}}>{name.replaceAll('_', ' ')}</TextLight></View>
      <TextLight style={{fontSize:14,color:'#777'}}>{data.desc}</TextLight>
      <ShareLink/>
      <ProductImage image={variant && variant.images} screen={LargeScreen()}/>
      <ButtonBuy/>
    </View>
    )
  }

  if(!data) return <NotFound/>

  const Head = (props) => {
    const {title} = props
    return (
      <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="site_name" content={title} />
                <meta name="title" content={title} data-react-helmet="true"/>
                <meta name="description" content="Whole New Entertaiment For your Home , Office & Ouutdoor We Bring More Funs to You and Family range of POOL , Billiard , Snooker , Foosball , Hockey Table , Ping Pong Table , Darts and More" />
                <meta name="keywords" content={data.keyword} />
                <meta itemprop="image" content="https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fmultirevolver%2Fmulti_pool_dark.jpg?alt=media&token=9d0f67f1-7e03-4c25-964b-87be04e9f468"></meta>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fmultirevolver%2Fmulti_pool_dark.jpg?alt=media&token=9d0f67f1-7e03-4c25-964b-87be04e9f468"></meta>
          </Helmet>
    )
  }


  return (
    <View style={{flex:1}}>
             <Head title={name.replaceAll('_', ' ')}/>
    <Whatsapp title={name.replaceAll('_', ' ')}/>
    <ScrollView style={{paddingHorizontal:'4%',paddingVertical:20}} ref={ref} >
        <View style={{flexDirection:'row',flexWrap:'wrap',flex:1}}>
          <ProductDetails/>
          <View>
              <Specification data={data.specification}/>
              <Accessories icon={'gift-outline'} data={data.accessories}/>
              <Weight data={data.weight}/>
          </View>
        </View>
        <View>
              <Dimension data={data.dimension}/>
        </View>
      <Footer nav={navigation} category={category} name={name}/>

    </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  nostockv:{
    backgroundColor:'red',
    borderRadius:4,
    padding:3,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  }
});
