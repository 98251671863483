import React, { Component } from "react";
import {View,Image,StyleSheet,Linking,Platform} from "react-native";
import Modal from "react-native-modal";
import Text, { TextLight } from "./text";
import Ionicons from '@expo/vector-icons/Ionicons';
import { TouchableOpacity } from "react-native-gesture-handler";


export default function Whatsapp(props) {

    const {title} = props;


    return (

          <View style={styles.container}>
            <TouchableOpacity onPress={() => Linking.openURL('whatsapp://send?text=Donkeez Sports'+'%0a%0a'+title+'&phone=+601156213067')}>
              <View style={{justifyContent:'center',alignContent:'center',alignItems:'center'}}>
             <Image source={require('../images/whatsapp.svg')} style={styles.icon}/>
             <View style={{flexDirection:'row',alignContent:'center',justifyContent:'center'}}>
             <View style={styles.txtbx}>
              <TextLight style={{fontSize:10,color:'#fff'}}>WHATSAPP</TextLight>
              </View>
              </View>
             </View>
             </TouchableOpacity>
          </View>

    );

  }


    const styles = StyleSheet.create({
      container:{
        ...Platform.select({
          web: {
            position: "fixed",
          }
        }),
        right:20,
        bottom:20,
        zIndex:99,
      },
      icon:{
        width:40,
        height:40
      },
      txtbx:{
        backgroundColor:'#444',
        paddingVertical:2,
        paddingHorizontal:4,
        borderRadius:5,
        shadowColor: '#ddd',
        shadowOffset: {
          width: 1,
          height: 1
        },
      }
    
});

  