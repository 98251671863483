export const Foosball = [{
    name:'Foosball',
    keyword:'mdf table,donkeez sports table,foosball,mdf table foosball,donkeez sports foosball,donkeez sports soccer table,soccer table',
    category:'foosball',
    latest:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2FFoosball.jpg?alt=media&token=efb0721e-2722-49ae-863f-48fa4705029f',
    discount:1799.00,
    amount:2499.00,
    stock:1,
    desc:'The total comfort Foosball table with playable for a whole family',
    accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
      weight:{'nett':'110KG','gross':'105KG'},
      dimension:[ 
                  {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2Fdimension%2Ffoosball_dimension_1.svg?alt=media&token=94e39c22-7f5e-4195-bf2a-f50ad951a638'},
                  {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2Fdimension%2Ffoosball_dimension_2.svg?alt=media&token=cfab32c7-e233-4431-bbd2-d43b244e22a7'},
                  {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2Fdimension%2Ffoosball_dimension_3.svg?alt=media&token=0cda1a62-638a-4155-8061-67466480f504'},
                ],
      variant:[{
              name:'BLACK',
              color:'#363936',
              images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2FFoosball.jpg?alt=media&token=efb0721e-2722-49ae-863f-48fa4705029f',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2Ffoosball2.jpg?alt=media&token=3c68159a-a0eb-4f37-99ab-9fe15be18f3a']
            }]
  },
  {
    name:'Fireball_Foosball',
    keyword:'mdf table,donkeez sports table,foosball,mdf table foosball,donkeez sports foosball,donkeez sports soccer table,soccer table',
    category:'foosball',
    discount:1299.00,
    amount:1699.00,
    stock:1,
    desc:'The total comfort Foosball table with playable for a whole family',
    accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
    weight:{'nett':'110KG','gross':'105KG'},
    dimension:[ 
                  {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2Fdimension%2Ffoosball_dimension_1.svg?alt=media&token=94e39c22-7f5e-4195-bf2a-f50ad951a638'},
                  {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2Fdimension%2Ffoosball_dimension_2.svg?alt=media&token=cfab32c7-e233-4431-bbd2-d43b244e22a7'},
                  {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2Fdimension%2Ffoosball_dimension_3.svg?alt=media&token=0cda1a62-638a-4155-8061-67466480f504'},
                ],
    variant:[{
              name:'BLACK',
              color:'#363936',
              images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffireball_foosball%2Ffireball_resize.png?alt=media&token=3754a59f-bb89-4ab7-bed1-872c56ce49a2',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffireball_foosball%2FSTOL-DO-GRY-PILKARZYKI-5FT-THUNDER-FIREBALL-Kod-producenta-FIREBALL-fotor-20230909174039.jpg?alt=media&token=c23b9440-6450-4491-b945-382398fd4f11',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffireball_foosball%2FSTOL-DO-GRY-PILKARZYKI-5FT-THUNDER-FIREBALL-Stan-opakowania-oryginalne-fotor-20230909173910.jpg?alt=media&token=2bdd90ef-1cf8-44e0-b50e-076a2000a93e'
            ]
            }]
  },
  {
    name:'Foosball_Bold',
    keyword:'mdf table,donkeez sports table,foosball,mdf table foosball,donkeez sports foosball,donkeez sports soccer table,soccer table',
    category:'foosball',
    discount:1999.00,
    amount:2999.00,
    stock:0,
    desc:'The total comfort Bold foosball with playable for a whole family',
    accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
      weight:{'nett':'110KG','gross':'105KG'},
      dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool.svg?alt=media&token=e23b5b66-7a6d-46c5-8da5-f7a2bbb935ed'},
    {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool.svg?alt=media&token=e23b5b66-7a6d-46c5-8da5-f7a2bbb935ed'},
    {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool.svg?alt=media&token=e23b5b66-7a6d-46c5-8da5-f7a2bbb935ed'}
    ],
    variant:[{
              name:'BLACK',
              color:'#363936',
              images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Ffoosball%2F33.JPG?alt=media&token=4c4bd1ba-43fa-445e-8776-656ef643489c']
            }]
  }
]



export default Foosball