import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image,TouchableOpacity} from 'react-native';
import Text,{TextLight} from '../../../assets/component/text';


export default function Desktopaccessory(props) {

  const {data,nav} = props

  const More = () => {
    return (
      <TouchableOpacity style={styles.listbx} onPress={()=>nav.navigate('products', {name: 'accessories'})}>
              <View style={{width:100,height:100,alignItems:'center',justifyContent:'center'}}>
                  <View style={[{flexDirection:'row',alignItems:'center'}]}>
                                      <View style={styles.dot}/>
                                      <View style={styles.dot}/>
                                      <View style={styles.dot}/>
                  </View>
                  <Text style={{fontSize:12,color:'#777'}}>view more</Text>
              </View>
      </TouchableOpacity>

    )
  }


    return (
        <View style={{flexDirection:'row',marginVertical:5,flexWrap:'wrap'}}>
          {  
          data.map((val,i)=>{
            const NavName = val.accessories
            const Category = val.category
            const pImage = val.variant && val.variant[0].images[0]
                return(
                      <TouchableOpacity style={[styles.listbx,{width:200}]} key={i} onPress={()=>nav.navigate('details', {category:Category,name:NavName})}>
                            <Image source={{uri:pImage}} style={styles.image}/>
                            <Text style={{fontSize:16}}>{val.name.replaceAll('_', ' ')}</Text>
                      </TouchableOpacity>
                )
            })
            }
            <More/>
        </View>
    )


}

const styles = StyleSheet.create({
  listbx:{
    alignItems:'center',
    flexDirection:'row',
    padding:4,
    borderWidth:1,
    marginBottom:10,
    marginRight:10,
    borderRadius:4,
    borderColor:'#f3f3f3',
    shadowColor: "#bbb",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  image:{
    width:100,
    height:100,
    marginRight:5,
    resizeMode: 'contain',
    aspectRatio: 1.77 // Your aspect ratio
  },
  dot:{
    width:10,
    height:10,
    borderRadius:10,
    margin:5,
    backgroundColor:'#777'
  }
});
