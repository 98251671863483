import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image,TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Text,{TextLight} from '../../../assets/component/text';


export default function Mobileaccessory(props) {

  const {data,nav} = props

  const More = () => {
    return (
        <TouchableOpacity style={styles.container} onPress={()=>nav.navigate('products', {name: 'accessories'})}>
          <View style={[styles.listbx,{justifyContent:'center',alignItems:'center'}]}>
              <View style={[{flexDirection:'row'}]}>
                                  <View style={styles.dot}/>
                                  <View style={styles.dot}/>
                                  <View style={styles.dot}/>
              </View>
              <Text style={{fontSize:12,color:'#777'}}>view more</Text>
          </View>
          <Text style={{fontSize:14,textAlign:'center'}}>More</Text>
      </TouchableOpacity>
    )
  }



    return (
        <ScrollView 
        style={{flexDirection:'row',marginVertical:5}} 
        horizontal={true}
        showsHorizontalScrollIndicator={false} 
        showsVerticalScrollIndicator={false}
        >
          {  
          data.map((val,i)=>{
                const NavName = val.name
                const Category = val.category
                const pImage = val.variant && val.variant[0].images[0]
                return(
                  <TouchableOpacity style={styles.container} key={i}  onPress={()=>nav.navigate('details', {category:Category,name:NavName})}>
                        <View style={styles.listbx}>
                            <Image source={{uri:pImage}} style={styles.image}/>
                        </View>
                        <Text style={{fontSize:14,textAlign:'center'}}>{val.name.replaceAll('_', ' ')}</Text>
                    </TouchableOpacity>
                )
            })
            }
            <More/>
        </ScrollView>
    )


}

const styles = StyleSheet.create({
  container:{
    alignItems:'center',
    width:100,
    marginRight:5,
  },
  listbx:{
    width:100,
    height:100,
    padding:4,
    borderWidth:1,
    marginBottom:10,
    marginRight:10,
    borderRadius:4,
    borderColor:'#f3f3f3',
  },
  image:{
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1.77 // Your aspect ratio
  },
  dot:{
    width:10,
    height:10,
    borderRadius:10,
    margin:5,
    backgroundColor:'#777'
  }
});
