import React, { useState,useEffect } from 'react';
import { StyleSheet,View,TouchableOpacity, Image,useWindowDimensions} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {MaterialIcons} from '@expo/vector-icons';
import { TextLight } from '../../../assets/component/text';
import {HeaderSitemap} from '../../../navigation/data';


export default function Types(props) {

  const {nav} = props
  const [selected, setSelected] = useState(true);

  
  useEffect(() => {

    
  }, []);
  


  const LargeScreen = () => {
    const dimensions = useWindowDimensions();
  
    const isLargeScreen = dimensions.width >= 768;
    return isLargeScreen
  }

  const ItemBx = () => {
    const Products = HeaderSitemap()[0].list
    if(!Products.length) return null
    return (
      <View style={{flexDirection:'row'}}>
        {Products.map((val,i)=> {
          const NavName = val.name.toLowerCase()
          return (
            <TouchableOpacity key={i} style={styles.bx} key={i} onPress={()=>nav.navigate('products', {name: NavName})}>
                <Image source={val.icon} style={styles.icon}/>
                <TextLight style={{fontSize:10,fontWeight:'300',color:'#777'}}>{val.name.replaceAll('_', ' ')}</TextLight>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  const ArrowBx = () => {
    if(!selected) return null
    return (
    <TouchableOpacity style={{justifyContent:'center',position:'absolute',right:0,height:'100%',paddingHorizontal:4,backgroundColor:'#f7f7f7',borderTopRightRadius:5,borderBottomRightRadius:5}} onPress={() => console.log("Cart")}>
         <MaterialIcons name="arrow-forward-ios" size={14} color="#777" />
    </TouchableOpacity>
    )
  }


  if(LargeScreen()) return (
    <View style={[styles.container,{justifyContent:'center'}]}>
        {ItemBx()}
    </View>
  )


  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    return layoutMeasurement.width + contentOffset.x >= contentSize.width;
  };



  return (
    <View style={[{flexDirection:'row'},styles.container]}>
      <ScrollView  
          horizontal={true} 
          showsVerticalScrollIndicator={false} 
          showsHorizontalScrollIndicator={false}
          onScroll={({nativeEvent}) => {
            if (isCloseToBottom(nativeEvent)) {
              setSelected(false)
            }else{
              setSelected(true)
            }
          }}
          >
                {ItemBx()}
      </ScrollView>
      {ArrowBx()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padd:10,
    borderWidth:1,
    flexDirection:'row',
    borderRadius:5,
    borderColor:'#ddd',
    alignContent:'center',
    marginBottom:20
  },
  bx:{
    minWidth:80,
    maxWidth:150,
    flex:1,
    marginVertical:5,
    justifyContent:'center',
    alignItems:'center'
  },
  icon:{
    width:50,
    height:50
  }
});
