import React, { useState,useEffect } from 'react';
import { StyleSheet,View, Image,useWindowDimensions} from 'react-native';
import Text,{TextLight} from '../../../assets/component/text';
import {Accessories} from '../../data'
import Desktop from './desktop';
import Mobile from './mobile';




export default function accessory(props) {

  const {nav} = props

  const [data, setData] = useState([]);

  useEffect(() => {
    GetProduct()
  }, []);

  async function GetProduct(){
    const Result = await Accessories(5)
    if(Result){
      setData(Result)
    }else{
      setData(null)
    }
  }

  const LargeScreen = () => {
    const dimensions = useWindowDimensions();
  
    const isLargeScreen = dimensions.width >= 768;
    return isLargeScreen
  }

  if(!data) return null


  return (

    <View>
            <View style={{marginVertical:10}}>
              <Text style={styles.head}>Accessories</Text>
              <TextLight>Shop with us for your accessories</TextLight>
            </View>
            {LargeScreen()?<Desktop data={data} nav={nav}/>:<Mobile data={data} nav={nav}/>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padd:10,
    borderWidth:1,
    flexDirection:'row',
    borderRadius:5,
    borderColor:'#ddd',
    alignContent:'center',
    marginBottom:20
  },
  head:{
    fontSize:16,
    fontWeight:'500'
  },
  listbx:{
    width:100,
    height:100,
    padding:4,
    borderWidth:1,
    marginBottom:10,
    marginRight:10,
    borderRadius:4,
    borderColor:'#f3f3f3',
  },
  image:{
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1.77 // Your aspect ratio
  }
});
