const Product = [
  { name:'6_Air_Hockey_Black', 
  keyword:'mdf table,donkeez sports table,foosball,mdf table foosball,donkeez sports foosball,donkeez sports soccer table,soccer table',
  category:'air_hockey',
  discount:1699.00,
  amount:2399.00,
  stock:1,
  desc:'The total comfort Air Hockey table with playable for a whole family',
  accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
  weight:{'nett':'110KG','gross':'105KG'},
  dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2Fdimension%2Fairhockey_dimension_1.svg?alt=media&token=ba323e8d-7c98-4955-832c-53cee2c55e46'},
  {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2Fdimension%2Fairhockey_dimension_2.svg?alt=media&token=e250c8be-ad1b-4ce3-b609-e4a48554d607'},
  {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2Fdimension%2Fairhockey_dimension_3.svg?alt=media&token=80ae835a-a0d4-4376-807e-aa7362c8249e'}
],
  variant:[{
            name:'Black',
            color:'#222',
            images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey_6%2Fblack_airhockey%20(1).png?alt=media&token=70f50b4d-a458-4d7d-b4dc-9e04671834f3',
            'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey_6%2FPage%201-fotor-bg-remover-2024021591236.png?alt=media&token=1466236b-c561-44cb-a8f5-1168fcaa5ce6']
          }]
},
{ name:'Air_Hockey', 
  keyword:'mdf table,donkeez sports table,foosball,mdf table foosball,donkeez sports foosball,donkeez sports soccer table,soccer table',
  category:'air_hockey',
  discount:1999.00,
  amount:4999.00,
  stock:1,
  desc:'The total comfort Air Hockey table with playable for a whole family',
  accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
  weight:{'nett':'110KG','gross':'105KG'},
  dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2Fdimension%2Fairhockey_dimension_1.svg?alt=media&token=ba323e8d-7c98-4955-832c-53cee2c55e46'},
  {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2Fdimension%2Fairhockey_dimension_2.svg?alt=media&token=e250c8be-ad1b-4ce3-b609-e4a48554d607'},
  {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2Fdimension%2Fairhockey_dimension_3.svg?alt=media&token=80ae835a-a0d4-4376-807e-aa7362c8249e'}
],
  variant:[{
            name:'Black',
            color:'#222',
            images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2F4bd38e83-7c38-4811-ab0c-05503074b2c2_1000.jpg?alt=media&token=43c99d69-55fb-4728-a3aa-c783f41acc1e',
            'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fairhockey%2F4bd38e83-7c38-4811-ab0c-05503074b2c2_1000.jpg?alt=media&token=43c99d69-55fb-4728-a3aa-c783f41acc1e']
          }]
}
]

export default Product


 