import React, { useState,useEffect } from 'react';
import { StyleSheet,View,Text, Image,useWindowDimensions} from 'react-native';
import Desktop from './desktop'
import Mobile from './mobile'

export default function Banners(props) {

  const {image,link} = props


  
  useEffect(() => {

    
  }, []);
  


  const LargeScreen = () => {
    const dimensions = useWindowDimensions();
  
    const isLargeScreen = dimensions.width >= 768;
    return isLargeScreen
  }





  return (

    <View style={{paddingVertical:10}}>
            {LargeScreen()?<Desktop img={image} link={link}/>:<Mobile img={image} link={link}/>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padd:10,
    borderWidth:1,
    flexDirection:'row',
    borderRadius:5,
    borderColor:'#ddd',
    alignContent:'center',
    marginBottom:20
  },
  largebx:{
    flexDirection:'row',
    flexWrap:'wrap',
    flex:1,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center'
  },
  bx:{
    minWidth:80,
    maxWidth:150,
    flex:1,
    height:50,
    marginVertical:5,
    justifyContent:'center',
    alignItems:'center'
  },
  image:{
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1.77 // Your aspect ratio
  }
});
