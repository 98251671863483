export const Pool =  [
    { name:'MDF_Pool_Table_(7FT)', 
      keyword:'mdf table,donkeez sports table,7ft mdf pool table,mdf table billiard,donkeez sports billiards',
      category:['pool'],
      discount:2299.00,
      amount:2999.00,
      stock:1,
      desc:'The total comfort American pool table with playable for a whole family',
      accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
      weight:{'nett':'110KG','gross':'105KG'},
      dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_1.svg?alt=media&token=8247eee1-2624-42ff-a98c-0a261700bdc7'},
                  {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_2.svg?alt=media&token=3bcee180-c156-4262-8dfd-75677e0f5752'},
                  {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_3.svg?alt=media&token=63664b3b-32b6-49f5-b8b5-b37c2726334d'}
                ],
      variant:[{
                  name:'BLUE',
                  color:'#3886c8',
                  images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_blue_1.jpg?alt=media&token=35f794cb-82d8-4903-ba1e-642c3f5aa587',
                          'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_blue_1.jpg?alt=media&token=35f794cb-82d8-4903-ba1e-642c3f5aa587',
                        ]
              },{
                  name:'RED',
                  color:'red',
                  images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_red-1.jpg?alt=media&token=c667ff82-8f24-481d-a7b9-8e223cb036cc',
                          ]
              },
              {
                name:'BLACK',
                color:'#444',
                images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_black_1.jpg?alt=media&token=c0039d85-f9ea-4547-af83-fee1ee8d5aaa'
                        ]
                }]
    },
    { name:'MDF_Pool_Table_(8FT)', 
      keyword:'mdf table,donkeez sports table,8ft mdf pool table,mdf table billiard,donkeez sports billiards',
      category:['pool'],
      discount:2999.00,
      amount:3299.00,
      stock:1,
      desc:'The total comfort American pool table with playable for a whole family',
      accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
      weight:{'nett':'95KG','gross':'105KG'},
      dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_1.svg?alt=media&token=8247eee1-2624-42ff-a98c-0a261700bdc7'},
      {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_2.svg?alt=media&token=3bcee180-c156-4262-8dfd-75677e0f5752'},
      {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_3.svg?alt=media&token=63664b3b-32b6-49f5-b8b5-b37c2726334d'}
    ],
      variant:[{
                name:'BLUE',
                color:'#3886c8',
                images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_blue_1.jpg?alt=media&token=35f794cb-82d8-4903-ba1e-642c3f5aa587',
                        'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_blue_1.jpg?alt=media&token=35f794cb-82d8-4903-ba1e-642c3f5aa587',
                        'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_blue_1.jpg?alt=media&token=35f794cb-82d8-4903-ba1e-642c3f5aa587']
                },{
                name:'RED',
                color:'red',
                images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_red-1.jpg?alt=media&token=c667ff82-8f24-481d-a7b9-8e223cb036cc',
                        'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_red-1.jpg?alt=media&token=c667ff82-8f24-481d-a7b9-8e223cb036cc']
              }]
    },
    { name:'Family_Foldable_Pool_Table_(6ft)', 
      keyword:'mdf table,donkeez sports table,6ft mdf pool table,mdf table billiard,donkeez sports billiards',
      category:['pool'],
      discount:1499.00,
      amount:2499.00,
      stock:1,
      desc:'6Ft foldable billiard table suitable for kids - adults comes with all assecories',
      accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
      weight:{'nett':'110KG','gross':'105KG'},
      dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_1.svg?alt=media&token=8247eee1-2624-42ff-a98c-0a261700bdc7'},
      {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_2.svg?alt=media&token=3bcee180-c156-4262-8dfd-75677e0f5752'},
      {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_3.svg?alt=media&token=63664b3b-32b6-49f5-b8b5-b37c2726334d'}
    ],
      variant:[{
                name:'BLUE',
                color:'#3886c8',
                images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fpool_blue_1.jpg?alt=media&token=35f794cb-82d8-4903-ba1e-642c3f5aa587',
                        'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2FDQ-P021%20(1).jpg?alt=media&token=bf771714-12fe-46dc-9b05-711856c6945c',]
                }]
    },
    { name:'Mini_Family_Billiard', 
      keyword:'mdf table,donkeez sports table,6ft mdf pool table,mdf table billiard,donkeez sports billiards',
      category:['pool'],
      discount:799.00,
      amount:999.00,
      stock:0,
      desc:'Mini billiard table suitable for kids comes with all assecories',
      accessories:['2 * pcs 57” cues','1 * triangle','1 * brush','1 * set 2-1/4” balls','2 * chalks'],
      weight:{'nett':'110KG','gross':'105KG'},
      dimension:[ {name:'front',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_1.svg?alt=media&token=8247eee1-2624-42ff-a98c-0a261700bdc7'},
                  {name:'top',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_2.svg?alt=media&token=3bcee180-c156-4262-8dfd-75677e0f5752'},
                  {name:'side',image:'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2Fdimension%2Fpool_dimension_3.svg?alt=media&token=63664b3b-32b6-49f5-b8b5-b37c2726334d'}
                ],
      variant:[{
                name:'RED',
                color:'red',
                images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2FDQ-P020%20(2).jpg?alt=media&token=c6d16fcf-e841-490a-a474-51ccd16b6953',
                        'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Fpool%2FDQ-P020%20(2).jpg?alt=media&token=c6d16fcf-e841-490a-a474-51ccd16b6953',]
                }]
    }
]



 export default Pool