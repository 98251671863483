import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,useWindowDimensions} from 'react-native';
import Text,{TextLight} from "../../assets/component/text";
import Newsletter from "./newsletter";
import Sitemap from "./sitemap";
import Breadcrumbs from "./breadcrumbs";
import Social from "./social";




export default function Footer(props) {

    const {nav,active} = props

    const LargeScreen = () => {
      const dimensions = useWindowDimensions();
    
      const isLargeScreen = dimensions.width >= 768;
      return isLargeScreen
    }




    const Copyright = () => {
      return (
        <View style={[styles.flexcenter,styles.copyright]}>
          <TextLight style={{fontSize:12,color:'#aaa'}}>© 2023 Copyright DONKEEZ SPORTS. All Rights Reserved.</TextLight>
        </View>
      )
    }

        return (
            <View style={styles.container}>
              <View style={[{paddingHorizontal:LargeScreen()?'10%':'4%'}]}>
                <Newsletter screen={LargeScreen()}/>
                <Social screen={LargeScreen()}/>
                  <Breadcrumbs list={props}/>
                  <Sitemap screen={LargeScreen()} nav={nav}/>
              </View>
              <Copyright/>
            </View>
        )

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      marginTop:50
    },
    flexcenter:{
      flexDirection:'row',
      alignItems:'center',
      alignContent:'center'
    },
    icon:{
      width:20,
      height:20
    },
    breadBtn:{
      paddingHorizontal:5
    },
    copyright:{
      padding:10,
      justifyContent:'center'
    },
    SiteMap:{
      flexDirection:'row',
      marginVertical:20
    },
    mSiteMap:{
      paddingVertical:10,
      borderBottomWidth:1,
      borderBottomColor:'#ccc'
    },
    mSiteMapLast:{
      paddingVertical:10,
    },
    bold:{
      fontWeight:'600',
      fontSize:12,
      color:'#999'
    }
});
