import React, { Component } from "react";
import {Text,StyleSheet} from "react-native";
import COLORS from "./colors";

export default props => <Text {...props} style={[styles.text, props.style]}>{props.children}</Text>


export const TextLight = (props) => {
    return (
        <Text {...props} style={[styles.textlight, props.style]}>{props.children}</Text>
    )
}


const styles = StyleSheet.create({
    text:{
        color:'#222'
    },
    textlight:{
        color:'#222',
        fontWeight:'300'
    },
  });


