import React, { Component } from "react";
import {View,TextInput,StyleSheet} from "react-native";
import COLORS from "./colors";
import {TextLight} from "./text";
import Ionicons from '@expo/vector-icons/MaterialIcons';


export default function TextInputCustom(props) {

    const {value,title, remark,hide,txtcolor,placeholder,required,onblur,placeholderTextColor,bdcolor} = props;

    const Title = () => {
      if(!title) return null
      return (
        <TextLight style={styles.title} numberOfLines={1}>{title}{required&&' *'}</TextLight>
      )
    }

    const Remark = () => {
      if(!remark) return null
      return (
        <TextLight style={styles.remark}>{remark}</TextLight>
      )
    }

    const InputBX = () => {
      const TxtColor = txtcolor?txtcolor:COLORS.Text
      const placetxt = placeholderTextColor?placeholderTextColor:'#ccc'
      const bDcolor = value.error?COLORS.Error:(bdcolor?bdcolor:'#aaa')
      if(!value.val){
        return (
          <TextInput onBlur={onblur} {...props} placeholderTextColor={placetxt} placeholder={placeholder?placeholder:title} value={value.error?'':value.toString()} style={[styles.input,{color:TxtColor,borderColor: bDcolor}]}/>
        )
      }
      return (
        <TextInput onBlur={onblur} {...props} placeholderTextColor={placetxt} placeholder={placeholder?placeholder:title} value={value.val.toString()} style={[styles.input,{color:TxtColor,borderColor:bDcolor}]}/>
      )
    }

    const ErrorBox = () => {
      if(!value.error) return null
      return (
        <View style={styles.errbox}><Ionicons name={"error"} size={18} color={COLORS.Error}/><TextLight style={styles.error}> {value.error}</TextLight></View>
      )
    }


    return (
      <View>
      <View style={styles.placeView}><Title/><Remark/></View>
      <InputBX/>
      <ErrorBox/>
      </View>
    );

  }


    const styles = StyleSheet.create({
      input: {
        paddingVertical:10,
        paddingHorizontal:10,
        fontSize:16,
        borderWidth:1,
        borderRadius:4,
        outlineWidth:0,
        fontWeight:'300'
      },
      error:{
        fontSize:12,
      },
      placeView:{
        flexDirection:'row',
        alignItems:'baseline',
        alignContent:'center',
      },
      title:{
        marginBottom:5,
        color:COLORS.Text,
        paddingHorizontal:5
      },
      remark:{
        fontSize:11,
        fontWeight:'300',
        color:'#aaa'
      },
      errbox:{
        flexDirection:'row',
        textAlign:'center',
        alignItems:'center',
        marginVertical:2
      }
    
});

  