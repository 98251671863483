import React, { useState,useEffect } from 'react';
import { View, useWindowDimensions,Text,Button } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import CustomDrawer from './CustomDrawer'
import Header from './header';
import Home from '../screens/Home'
import {Details,List} from '../screens/Product'
import NotFound from '../screens/notfound'
import Warranty from '../screens/Warranty'
import ContactUs from '../screens/ContactUs'
import AboutUs from '../screens/AboutUs'
import './App.css';

const linking = {
  prefixes: ["http://localhost*", "https://www.donkeez.com/app"],
  config: {
    screens: {
      Home: '',
      products: 'products/:name',
      details: ':category/:name',
      warranty: 'warranty/:name',
      contact_us: 'contact_us/:name',
      about_us: 'about_us/:name',
      NotFound: '*',
    }
  },
};

const MyTheme = {
  dark: false,
  colors: {
    primary: 'rgb(255, 45, 85)',
    background: '#fff',
    card: 'rgb(255, 255, 255)',
    text: '#444',
    border: 'rgb(199, 199, 204)',
    notification: 'rgb(255, 69, 58)',
  },
};




const LargeScreen = () => {
  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 768;
  return isLargeScreen
}


const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();


export default function App() {



  return (
    <NavigationContainer linking={linking} theme={MyTheme}>
       <Drawer.Navigator 
        backBehavior='history'
        screenOptions={{
          swipeMinDistance:0,
          drawerPosition:'right',
          drawerStyle: {
              width: LargeScreen()?0:'100%',
              backgroundColor: 'transparent',
              flex:1,
              top:50,
          },
          overlayColor: 'transparent',
          drawerType: LargeScreen() ? 'back' : 'front',
          header: props => <Header {...props}  />,
        }}
        drawerContent={(props) => <CustomDrawer {...props} />}
       >
        <Drawer.Screen name="Home" component={Home} />
        <Drawer.Screen name="products" component={List} initialParams={{ name:'null' }}/>
        <Drawer.Screen name="details" component={Details} initialParams={{ name:'null',category:'null' }}/>

        <Drawer.Screen name="warranty" component={Warranty} />
        <Drawer.Screen name="contact_us" component={ContactUs} />
        <Drawer.Screen name="about_us" component={AboutUs} />
        <Drawer.Screen name="NotFound" component={NotFound} />


        
      </Drawer.Navigator>

    </NavigationContainer>
  );
}
