import React, { useState,useEffect } from 'react';
import { StyleSheet,View,useWindowDimensions, TouchableOpacity,Image } from 'react-native';
import AboutUs from './AboutUs';
import History from './History';
import NotFound from '../notfound';



export default function WarrantyPage({route,navigation}) {

  const {name} = route.params;

  useEffect(() => {


  }, []);
  

  if(decodeURI(name) == 'about us') return <AboutUs nav={navigation}/>
  if(decodeURI(name) == 'history') return <History nav={navigation}/>

  return <NotFound/>

}

const styles = StyleSheet.create({
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  titlebx:{
    padding:5,
    alignContent:'flex-end',
    alignItems:'flex-end'
  },

  dimensionimg:{
    flex: 1,
    height:360,
    minWidth:200,
    maxWidth:400,
    padding:20
  },
});
