import React, { useState,useEffect } from 'react';
import { StyleSheet,View,Image,Share,Alert} from 'react-native';
import Text,{TextLight} from '../../../assets/component/text';
import Modal from '../../../assets/component/modal';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import SimpleLineIcons from '@expo/vector-icons/SimpleLineIcons';
import * as Sharing from 'expo-sharing';
import { TouchableOpacity } from 'react-native-gesture-handler';




  export const FreeDelivery = () => {
    return (
      <View style={[styles.flexcenter]}>
          <View style={[styles.flexcenter,{paddingVertical:5,paddingHorizontal:10,borderWidth:1,borderColor:'#51b2b6',marginBottom:10,borderRadius:4}]}>
            <MaterialCommunityIcons name="truck" size={22} color="#51b2b6"/>
            <View style={{marginLeft:5}}>
                <TextLight style={{fontSize:12,color:'#51b2b6',fontWeight:'500'}}>FREE DELIVERY</TextLight>
                <TextLight style={{fontSize:10,color:'#51b2b6'}}>WEST MALAYSIA</TextLight>
            </View>
          </View>
      </View>
    )
  }

  export const ColorVariant = (props) => {

    const {data,value,set} = props
    if(!data) return null


    return (
      <View style={{paddingVertical:10}}>
        <TextLight style={{fontSize:14,fontWeight:'400'}}>Colour</TextLight>
        <TextLight style={{fontSize:13,color:'#aaa',marginBottom:2}}>choose variants</TextLight>
        <View style={{flexDirection:'row',marginTop:5}}>
          {data.map((item)=> {
              const Active =  item.name == value.name
              return (
                <TouchableOpacity style={[styles.variantbx,{borderWidth:Active?2:1,borderColor:Active?item.color:'#ccc'}]} key={item.name} onPress={()=>set(item)}>
                    <View style={[styles.variantbxinner,{backgroundColor:item.color}]}/>
                </TouchableOpacity>
              )
          })}
        </View>
      </View>
    )
  }



  export const ColorVariant2 = (props) => {

    const {data,value,set} = props
    if(!data) return null


    return (
      <View style={{paddingVertical:10}}>
        <TextLight style={{fontSize:14,fontWeight:'400'}}>Colour</TextLight>
        <TextLight style={{fontSize:13,color:'#aaa',marginBottom:2}}>choose variants</TextLight>
        <View style={{flexDirection:'row',marginTop:5}}>
          {data.map((item)=> {
              const Active =  item.name == value.name
              return (
                <TouchableOpacity style={[styles.variantbx,{borderColor:Active?item.color:'#ccc', borderWidth:Active?2:1}]} key={item.name} onPress={()=>set(item)}>
                    <View style={{width:Active?'100%':20,height:Active?'100%':20,borderRadius:'100%',backgroundColor:item.color}}/>
                </TouchableOpacity>
              )
          })}
        </View>
      </View>
    )
  }


  export const Accessories = (props) => {

    const {icon,data} = props
    if(!data) return null

    return (

          <View style={{padding:10,minWidth:200,maxWidth:400}}>
            <View style={[styles.flexcenter,{paddingBottom:8,borderBottomWidth:1,borderColor:'#ccc',borderStyle:'dashed'}]}>
                <Ionicons name={icon} size={15} color="#777"/>
                <Text style={{marginLeft:5,fontSize:16}}>Free Accessories</Text>
            </View>
            <View style={{padding:10}}>
                  {data.map((item,i) => {
                        return <TextLight key={i} style={{fontSize:14,marginRight:5}}> - {item}</TextLight>
                    })}
            </View>
            <TextLight style={{fontSize:12,marginBottom:5,color:'#aaa'}}>* Item included in package</TextLight>
          </View>

    )
  }

  export const Specification = (props) => {

    const {icon,data} = props
    if(!data) return null

    return (

          <View style={{padding:10,minWidth:200,maxWidth:400}}>
            <View style={[styles.flexcenter,{paddingBottom:8,borderBottomWidth:1,borderColor:'#ccc',borderStyle:'dashed'}]}>
                <Ionicons name={icon} size={15} color="#777"/>
                <Text style={{marginLeft:5,fontSize:16}}>Specification</Text>
            </View>
            <View style={{padding:10,flexDirection:'row'}}>

            <View style={{marginRight:10}}>
                    {data.map((item,i) => {
                        return  <TextLight key={i} style={styles.Specificationtxt}>{item.name}</TextLight>
                    })}
            </View>
            <View>
                    {data.map((item,i) => {
                        return  <TextLight key={i} style={styles.Specificationtxt}>:  {item.desc}</TextLight>
                    })}
            </View>

            </View>
          </View>

    )
  }


  export const Dimension = (props) => {

    const {data} = props


    if(!data) return null

    return (

          <View style={{padding:10,flex:1}}>
            <View style={[styles.flexcenter,{paddingBottom:8,borderBottomWidth:1,borderColor:'#ccc',borderStyle:'dashed'}]}>
                <Image source={require('./images/dimension.svg')} style={styles.smallicon2}/>
                <Text style={{marginLeft:1,fontSize:16}}>Dimension</Text>
            </View>
            <View style={{flexWrap:'wrap',flexDirection:'row',flex:1}}>

                  {data.map((item,i) => {
                        return (
                          <View style={styles.dimensionimg} key={i}>
                              <View style={{alignItems:'center'}}><TextLight style={{textTransform: 'capitalize',color:'#ff7a7a',fontWeight:'400'}}>{item.name}</TextLight></View>
                              <Image source={{uri:item.image}} style={styles.image3}/>
                          </View>
                        )
                    })}
            </View>
            <TextLight style={{fontSize:12,marginBottom:5,color:'#aaa'}}>* Item dimension may vary from actual time of production</TextLight>
          </View>

    )
  }

  export const ShareLink = () => {

    const onShare = async () => {
      Sharing.shareAsync('url')
    };

    return (
      <TouchableOpacity style={{flexDirection:'row',paddingVertical:10}} onPress={()=>onShare()}>
          <View style={{borderRadius:8,flexDirection:'row',backgroundColor:'#ff5757',paddingVertical:2,paddingHorizontal:10}}>
            <Ionicons name={"share-social-outline"} size={14} color="#fff"/>
            <Text style={{fontSize:12,color:'#fff',marginHorizontal:4}}>SHARE</Text>
          </View>
      </TouchableOpacity>
    )
  }


  export const Weight = (props) => {

    const {data} = props

    if(!data) return null

    return (

          <View style={{padding:10,flex:1,minWidth:200,maxWidth:400}}>
            <View style={[styles.flexcenter,{paddingBottom:8,borderBottomWidth:1,borderColor:'#ccc',borderStyle:'dashed'}]}>
                <Image source={require('./images/scale.svg')} style={styles.smallicon}/>
                <Text style={{marginLeft:3,fontSize:16}}>Weight</Text>
            </View>
            <View style={{padding:10}}>
              <TextLight style={{fontSize:14,marginRight:5}}> Nett {data.nett}</TextLight>
              <TextLight style={{fontSize:14,marginRight:5}}> Gross {data.gross}</TextLight>
            </View>
          </View>

    )
  }

  const Bullet = (props) => {
    const {images,selected} = props

    return (
      <View style={{flexDirection:'row',justifyContent:'center',padding:10}}>
          {images.map((item,i) => {
              const bstyle = item == selected ? styles.sbullet : styles.bullet
             return <View style={bstyle} key={i}/>
        })}
      </View>
    )
  }


  export const ProductImage = (props) => {

    const {image,screen} = props

    if(screen) return ProductImageLargeScreen(props)
    
    if(!image) return <View style={{height:300}}/>

    const [selected, setSelected] = useState(image[0]);
    const [full, setFull] = useState(false);
    const [icnre, setIcnre] = useState(0);
    


    function Incred(){
      let newValue = icnre >= (image.length-1) ? 0 :icnre + 1;
      setIcnre(newValue)
      setSelected(image[newValue])
    }

    const Footer = () => {
      return (
        <View style={{flexDirection:'row',justifyContent:'flex-end'}}>
          <TextLight style={{fontSize:12,color:'#999'}}>Image {icnre+1} of {image.length}</TextLight>
        </View>
      )
    }


    return (
      <View>
        <TouchableOpacity onPress={()=>Incred()} style={{height:300}}>
            <Image source={{uri: selected,cache: 'force-cache'}} style={styles.image}/>
          </TouchableOpacity>
          <Bullet images={image} selected={selected}/>
          <TouchableOpacity onPress={()=>setFull(true)} style={styles.fullbtn}>
              <SimpleLineIcons name={"size-fullscreen"} size={18} color="#777"/>
          </TouchableOpacity>

          <Modal visible={full} setVisible={setFull} animationIn={'zoomIn'}>
              <TouchableOpacity onPress={()=>Incred()} style={{height:400}}>
                  <Image source={{uri: selected,cache: 'force-cache'}} style={styles.pimage}/>
              </TouchableOpacity>
              <Footer/>
          </Modal>
      </View>
    )

  }


  export const ProductImageLargeScreen = (props) => {

    const {image} = props
    
    if(!image) return <View style={{height:300}}/>

    const [selected, setSelected] = useState(image[0]);
    const [full, setFull] = useState(false);
    const [icnre, setIcnre] = useState(0);
    


    function Incred(){
      let newValue = icnre >= (image.length-1) ? 0 :icnre + 1;
      setIcnre(newValue)
      setSelected(image[newValue])
    }

    const Thumb = (active) => {
      return (
        <View style={{height:400,flexWrap:'wrap'}}>
          {image.map((i) => {
            const Actv = active == i ? '#ffcfc8' :  '#f3f3f3'
            return (
              <TouchableOpacity style={[styles.imagethumb,{borderColor: Actv}]} onPress={()=>setSelected(i)}>
                <Image source={{uri: i,cache: 'force-cache'}} style={styles.image}/>
              </TouchableOpacity>
            )
          })}
        </View>
      )
    }


    return (
      <View style={{flexDirection:'row'}}>
        <View style={{flex:1}}>
            <TouchableOpacity onPress={()=>Incred()} style={{height:300}}>
                <Image source={{uri: selected,cache: 'force-cache'}} style={styles.image}/>
            </TouchableOpacity>
        </View>
        {Thumb(selected)}
      </View>
    )

  }

  

  

const styles = StyleSheet.create({
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  imagethumb:{
    width:70,
    height:70,
    padding:4,
    borderWidth:2,
    marginBottom:10,
    marginRight:10,
    borderRadius:4,
    shadowColor: "#aaa",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  image3: {
    flex: 1,
    resizeMode: 'contain',
    marginVertical:10
  },
  dimensionimg:{
    flex: 1,
    height:250,
    minWidth:200,
    maxWidth:500,
    padding:20
  },
  pimage: {
    flex: 1,
    resizeMode: 'contain',
  },
  bullet:{
    width:5,
    height:5,
    margin:2,
    borderRadius:10,
    backgroundColor:'#ddd'
  },
  sbullet:{
    width:7,
    height:7,
    margin:2,
    borderRadius:10,
    backgroundColor:'#aaa'
  },
  fullbtn:{
    position:'absolute',
    bottom:40,
    right:20,
    padding:10
  },
  smallicon:{
    resizeMode: 'contain',
    width:17,
    height:17
  },
  smallicon2:{
    resizeMode: 'contain',
    width:24,
    height:24
  },
  variantbx:{
    borderWidth:1,
    width:30,
    height:30,
    padding:4,
    borderRadius:15,
    marginRight:5,
  },
  variantbxinner:{
    width:'100%',
    height:'100%',
    borderRadius:15
  },
  Specificationtxt:{
    textTransform: 'capitalize',
    fontSize:14
  }
});
