export const Accessories = [{
  name:'Gloves',
  keyword:'Rubber Gloves',
  category:'accessories',
  discount:399.00,
  amount:420.00,
  stock:1,
  desc:'Use high-quality fabric, which is silky and smooth, allowing you to play billiards with a higher penetration rate as you like',
  specification:[
      {name:'size',desc:'18*9cm/7.08*3.54inch'},
      {name:'Color',desc:'Black'},
      {name:'Material',desc:'Polyester'},
  ],
  weight:{'nett':'8.5KG','gross':'10KG'},
  variant:[{
            name:'BLACK',
            color:'#363936',
            images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fgloves.jpg?alt=media&token=2ba60c80-c439-49e4-b7e8-e8d1e674642e',
                    'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fgloves%2Fgloves1.jpg?alt=media&token=d5b5288b-173b-4121-b61e-649263760822'
           ]
  }]
},{
  name:'Paddles',
  keyword:'Ping Pong Bats',
  category:'accessories',
  discount:459.00,
  amount:799.00,
  stock:1,
  desc:'After a year of development, testing and iteration with professional coaches and athletes, our Elite Series Carbon Table Tennis Racket was born. And when we say this racket will improve your game instantly. We mean it.',
  specification:[
      {name:'Details',desc:'7-ply blade - 5-layers of premium wood + 2-layers of carbon fiber'},
      {name:'Surface',desc:'Rubber surface optimized for spin'},
      {name:'Design',desc:'Sleek design'},
  ],
  weight:{'nett':'8.5KG','gross':'10KG'},
  variant:[{
            name:'BLACK',
            color:'#363936',
            images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fbats%2FCarbonMain_single.jpg?alt=media&token=6ed9e77a-7161-4a40-8122-2d88f9205817',
                    'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fbats%2FCarbonLightweight.jpg?alt=media&token=d0e8d043-f8b8-4bd1-8bef-b006a4fec648',
                    'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fbats%2FCarbonCalloutLR.jpg?alt=media&token=5c44c7cf-b7a5-4a49-893d-dfe9a19061a9'
          ]
  }]
},{
    name:'Cues',
    keyword:'Cues Billiard',
    category:'accessories',
    discount:189.00,
    amount:299.00,
    stock:1,
    desc:'After a year of development, testing and iteration with professional player, our Elite Billiard Cues series',
    specification:[
        {name:'Size',desc:'57"'},
        {name:'Material',desc:'Wood'},
    ],
    weight:{'nett':'0.5KG','gross':'0.5KG'},
    variant:[{
              name:'BLACK',
              color:'#363936',
              images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fcue.jpg?alt=media&token=17161377-3707-40ab-86ae-0c19b535accd'
            ]
    }]
  },
  {
    name:'Balls',
    keyword:'Full Resin billiard American balls standard size with all in 1 sets colors',
    category:'accessories',
    discount:99.00,
    amount:199.00,
    stock:1,
    desc:'The total comfort Electronic Dart Board with playable for a whole family Power: By a adapter,Display: 4 LED Display',
    specification:[
      {name:'Size',desc:'5.25 X 5.25 X 5.25cm'},
      {name:'Ball Weight',desc:'142g'},
      {name:'Balls',desc:'16'},
      {name:'Materials',desc:'Synthetic Resin'},
    ],
    weight:{'nett':'2.5KG','gross':'2.8KG'},
    variant:[{
              images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fballs.jpg?alt=media&token=6f1311b9-0bda-4301-a515-d3c4b5b9dbe5',
            ]
    }]
  },
  {
    name:'Dart',
    keyword:'darts,Electronic Dart Board,donkeez sports darts,cabinet with eletronic darts,donkeez sports darts,donkeez sports soccer table,soccer table',
    category:'accessories',
    discount:659.00,
    amount:799.00,
    stock:1,
    desc:'Following years of success as one of Harrows most popular tungsten dart ranges, the second generation of ICE darts have arrived; Ice Recut. Designed with the finest injection molded 90% tungsten with Ice effect precision milling, combined with a variety of ring cuts. The Ice Recut are finished with a tough, black titanium nitride and a stunning blue metallic coating. These darts showcase a stunning combination of exceptional machining and performance.',
    specification:[
        {name:'Barrel',desc:'90% Tungsten'},
        {name:'Shaft',desc:'Silver Supergrip Carbon INB shafts'},
    ],
      weight:{'nett':'22g, 24g, and 26g','gross':'26g'},
    variant:[{
              name:'BLACK',
              color:'#363936',
              images:['https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fdarts%2FTaipan_dart_latest.jpg?alt=media&token=3ca2f106-f56f-4f80-9989-23ad7d419163',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fdarts%2Ftaipan-rotated_png.jpg?alt=media&token=326d9422-417f-442d-a46a-05614d0c7c92',
              'https://firebasestorage.googleapis.com/v0/b/donkeezacc-edb84.appspot.com/o/sites%2Fproducts%2Faccessories%2Fdarts%2Ftaipan-closeup_jpg_egdetail.jpg?alt=media&token=c7003408-f867-472e-a217-f0db9e9136df',
            ]
    }]
  }
]



export default Accessories