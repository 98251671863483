import React, { useState,useEffect } from 'react';
import {SafeAreaView,View,StyleSheet,Image,Linking} from 'react-native';
import {DrawerContentScrollView,DrawerItemList,DrawerItem,useDrawerProgress} from '@react-navigation/drawer';
import Animated,{ interpolate, Extrapolate, useAnimatedStyle } from 'react-native-reanimated';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import Text,{TextLight} from '../assets/component/text';
import {HeaderSitemap} from './data';




const SubMenu = (data) => {
  const SubMenu = data.sub
  const ScreenName = data.state.routeNames[data.state.index]
  const Screen = SubMenu.includes(ScreenName)

  const [expand, setExpands] = useState(false);

  useEffect(() => {
    setExpands(Screen)

  }, [data]);

  const SubItem = () => {
    const Link = data.label.toLowerCase()
    return  (
            <View style={{marginHorizontal:20}}>
                {SubMenu.map(key => {
                return <TouchableOpacity key={key.name} onPress={()=>data.navigation.navigate(Link, {name: key.name.toLowerCase()})} style={{padding:5,borderRadius:4,margin:5}}><TextLight style={{color:'#aaa'}}>{key.name.replaceAll('_', ' ')}</TextLight></TouchableOpacity>; 
                })}
            </View>
        )
  }


  return (
    <View>
      <DrawerItem
          label={data.label.replaceAll('_', ' ')}
          onPress={() => setExpands(!expand)}
          labelStyle={{color:'#ddd',fontWeight:'300', textTransform: 'capitalize'}}
        />
        {expand ?<SubItem/>:null}
    </View>
  )
}


const SubMenuIcon = (data) => {
  const Products =  HeaderSitemap().find(element => element.name === 'Products');
  return  (
        <View style={{padding:18}}>
          <View style={{marginBottom:10}}>
              <Text style={styles.txt2}>{Products.name}</Text>
              <TextLight style={{fontSize:12,color:'#aaa'}}>Full ranges of donkeez sports products</TextLight>
          </View>
          <View style={{marginVertical:10,flexDirection:'row',flexWrap:'wrap'}}>
              {Products.list.map((key,i) => {
              return (
                <TouchableOpacity key={i} onPress={()=>data.navigation.navigate('products', {name: key.name.toLowerCase()})} style={styles.subicon}>
                   <Image source={key.icon} style={styles.icon} resizeMode="contain"/>
                    <TextLight style={styles.icontxt}>{key.name.replaceAll('_', ' ')}</TextLight>
                </TouchableOpacity> 
              )})}
          </View>
        </View>
      )
}


 
const CustomSidebarMenu = (props) => {



  const drawerProgress = useDrawerProgress();
  const animatedStyle = useAnimatedStyle(() => {
    const scale = interpolate(drawerProgress.value, [0, 1], [0, 1], {
      extrapolateRight: Extrapolate.CLAMP,
    });
    const borderRadius = interpolate(drawerProgress.value, [0, 1], [2, 10], {
      extrapolateRight: Extrapolate.CLAMP,
    });
    const opacity = interpolate(drawerProgress.value, [0, 1], [0.2, 1], {
      extrapolateRight: Extrapolate.CLAMP,
    });

    return {
      transform: [{ scale }],
      borderRadius:[{borderRadius}],
      opacity:opacity,
      backgroundColor:'#2b2b2a',
      borderTopWidth:1,
      borderColor:'#333',
      flex:1
    };
  });

  const ListMenu = (props) => {
    const MenuList = HeaderSitemap().filter(item => item.name !== 'Products');
    return (
          <View>
            {
              MenuList.map((item,i)=> {
                return (
                  <SubMenu key={i} {...props} sub={item.list} label={item.name}/>
                )
              })
            }
          </View>
    )

  }


  return (
    <Animated.View style={animatedStyle}>
    <DrawerContentScrollView {...props}>
        <SubMenuIcon {...props} />
        <ListMenu {...props}/>
        <DrawerItem label="Help" onPress={() => alert('Link to help')} />
    </DrawerContentScrollView>
    </Animated.View>
  );


};
 
const styles = StyleSheet.create({
  sideMenuProfileIcon: {
    resizeMode: 'center',
    width: 130,
    height: 60,
    alignSelf: 'center',
  },
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  txt:{
    color:'#ddd',
    fontSize:14,
    fontWeight:'300',
    textTransform: 'capitalize',
  },
  txt2:{
    color:'#ddd',
    fontSize:15,
    fontWeight:'400',
    textTransform: 'capitalize',
  },
  icontxt:{
    color:'#aaa',
    fontSize:14,
    fontWeight:'300',
    textTransform: 'capitalize',
  },
  icon:{
    width:75,
    height:75
  },
  subicon:{
    width:75,
    marginRight:20,
    marginBottom:10,
    alignItems:'center',
  }
});
 
export default CustomSidebarMenu;